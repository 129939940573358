/**
 * Created by olga on 14.06.2024
 */

var ExtraSlotButton = cleverapps.UI.Button.extend({
    ctor: function (extraSlot) {
        this.extraSlot = extraSlot;
        this.appeared = false;
        this._super({
            content: this.getContent(),
            spine: bundles.game.jsons.extra_slot_button_json,
            width: cleverapps.styles.ExtraSlotButton.width,
            height: cleverapps.styles.ExtraSlotButton.height,
            onClicked: function () {
                if (cleverapps.forces.isRunningForce(Forces.FREE_EXTRA_SLOT_FORCE)) {
                    cleverapps.forces.closeRunningForce();
                }
                if (this.appeared) {
                    extraSlot.onClicked.call(extraSlot);
                }
            }.bind(this),
            onMouseOver: function () {
                if (this.background.getCurrentAnimationName() !== "hover") {
                    this.background.setAnimation(0, "hover", true);
                }
            }.bind(this),
            onMouseOut: function () {
                this.background.setAnimation(0, "idle", true);
            }.bind(this)

        });

        this.setCascadeOpacityEnabledRecursively(true);

        this.updateOffer();

        if (this.extraSlot.offer.force) {
            this.showForce(this.extraSlot.offer.force);
        }

        extraSlot.on("update", this.update.bind(this), this);
        extraSlot.on("use", this.onUsed.bind(this), this);
        extraSlot.on("showAlert", this.showAlert.bind(this), this);
        extraSlot.on("removeExtraSlotButton", this.animateDisappear.bind(this), this);
    },

    getContent: function () {
        var content = new cc.Node();
        var price = this.price = new TextWithIcon(this.extraSlot.offer.text, {
            font: cleverapps.styles.FONTS.EXTRA_SLOT_BUTTON
        });
        price.setPositionRound(cleverapps.styles.ExtraSlotButton.price);
        content.addChild(price);
        return content;
    },

    update: function (enabled) {
        if (enabled) {
            this.enable();
        } else {
            this.disable();
        }
    },

    showForce: function (force) {
        cleverapps.meta.displayWhenFreeFocus({
            focus: "ExtraSlotForce",
            actions: [
                function (f) {
                    cleverapps.forces.create(this, force);
                    cleverapps.forces.onceForceClosed = f;
                }.bind(this)
            ]
        });
    },

    updateOffer: function () {
        var offer = this.extraSlot.offer;

        if (offer.priceType === "ads") {
            this.background.setSkin("adds");
            this.price.setVisible(false);
        } else {
            this.background.setSkin("coin");
            this.price.setVisible(true);
            this.price.setString(offer.text);
            this.price.fitTo(this.width);
        }
    },

    onUsed: function () {
        this.clickHandler.remove();
        this.hoverHandler.remove();
        var movingNode = cleverapps.scenes.getMovingNode(this);
        this.content.runAction(new cc.FadeOut(0.1));

        var shine = new cleverapps.Spine(bundles.game.jsons.extra_slot_explosion_json);
        shine.setPositionRound(cleverapps.styles.ExtraSlotButton.explosion);
        this.addChild(shine);
        shine.replaceParentSamePlace(movingNode);

        var explosion = new cleverapps.Spine(bundles.card.jsons.explode_json);
        explosion.setSkin("yellow");
        explosion.setPositionRound(cleverapps.styles.ExtraSlotButton.explosion);
        this.addChild(explosion);
        explosion.replaceParentSamePlace(movingNode);
        this.background.setAnimation(0, "close", false);
        cleverapps.audio.playSound(bundles.game.urls.extra_slot_explode_effect);
        this.background.setCompleteListener(function () {
            this.removeFromParent(true);
            explosion.setAnimation(0, cleverapps.Random.choose(CardView.getExplodeAnimationNames()), false);
            explosion.setCompleteListenerRemove();
            shine.setAnimation(0, "animation", false);
            shine.setCompleteListenerRemove();
        }.bind(this));
    },
    
    animateAppear: function () {
        cleverapps.audio.playSound(bundles.game.urls.extra_slot_show_up_effect);
        var basePosition = this.getPosition();
        this.setPosition(basePosition.x, basePosition.y + cleverapps.styles.ExtraSlotButton.offsetY);
        this.runAction(new cc.Sequence(
            AnimationsLibrary.showUp(this),
            new cc.MoveTo(0.2, basePosition).easing(cc.easeOut(1)),
            new cc.ScaleTo(0.1, 0.95).easing(cc.easeIn(1)),
            new cc.ScaleTo(0.1, 1).easing(cc.easeOut(1)),
            new cc.CallFunc(function () {
                this.appeared = true;
            }.bind(this))
        ));
    },

    animateDisappear: function () {
        this.background.setCompleteListener(function () {
            this.removeFromParent(true);
            this.extraSlot.expireOffer();
            this.extraSlot.openCards.trigger("removeExtraSlot");
        }.bind(this));
        this.background.setAnimation(0, "off", false);
        this.appeared = false;

        this.price && this.price.runAction(
            new cc.Sequence(
                new cc.ScaleTo(0.35, 1.2).easing(cc.easeOut(1)),
                new cc.Spawn(
                    new cc.ScaleTo(0.15, 0.8).easing(cc.easeIn(1)),
                    new cc.FadeOut(0.15)
                )
            )
        );
    }
});

cleverapps.styles.FONTS = cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    EXTRA_SLOT_BUTTON: {
        size: 28,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    }

});

cleverapps.styles.ExtraSlotButton = {
    width: 120,
    height: 132.22,
    explosion: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: 0 }
    },

    price: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: -35 }
    },

    offsetY: 200
};
