/**
 * Created by Ivan on 12.04.2024
 */

cleverapps.styles.PaginationView = {
    position: [
        {
            x: { align: "center", dx: 0 },
            y: { align: "top", dy: -120 }
        },
        {
            x: { align: "center", dx: 0 },
            y: { align: "top", dy: -120 }
        },
        {
            x: { align: "center", dx: 0 },
            y: { align: "top", dy: -30 }
        }
    ]
};