/**
 * Created by olga on 14.06.2024
 */

var ExtraSlot = function (openCards) {
    cleverapps.EventEmitter.call(this);

    this.openCards = openCards;
    this.used = false;

    this.offer = Game.currentGame.prolongation.getOffer(Prolongation.TYPES.SLOT, false);

    if (this.offer.priceType === "ads") {
        cleverapps.adsLimits.on("update", this.updateAdState.bind(this), Game.currentGame);
    }
    Game.currentGame.prolongation.on("acceptOffer", this.onAcceptOffer.bind(this), this);
    this.openCards.on("warning", this.showAlert.bind(this), this);
};

ExtraSlot.prototype = Object.create(cleverapps.EventEmitter.prototype);
ExtraSlot.prototype.constructor = ExtraSlot;

ExtraSlot.prototype.onAcceptOffer = function (offer) {
    if (offer.type === Prolongation.TYPES.SLOT) {
        this.use();
    }
};

ExtraSlot.prototype.updateAdState = function () {
    if (this.used) {
        return;
    }

    this.trigger("update", cleverapps.adsLimits.state(AdsLimits.TYPES.PROLONGATION) === AdsLimits.STATE_READY);
};

ExtraSlot.prototype.onClicked = function () {
    if (this.openCards.counter.isActive()) {
        return;
    }
    Game.currentGame.prolongation.acceptOffer(this.offer);
};

ExtraSlot.prototype.use = function () {
    Game.currentGame.counter.setTimeout(function () {}, ExtraSlot.EXPLODE_TIMEOUT);
    this.openCards.incLimit();
    this.used = true;
    this.trigger("use");
    runCleaners(this);
};

ExtraSlot.prototype.showAlert = function (warning) {
    if (warning) {
        this.trigger("showAlert");
    }
};

ExtraSlot.prototype.isOfferAvailable = function () {
    return !this.used && ExtraSlot.isAvailable();
};
ExtraSlot.prototype.expireOffer = function () {
    this.used = true;
};

ExtraSlot.EXPLODE_TIMEOUT = 1200;

ExtraSlot.isAvailable = function () {
    return levels.user.checkAvailable(cleverapps.Availables.EXTRA_SLOT);
};
