/**
 * Created by mac on 12/08/22
 */

var Tile3Scene = TileGameScene.extend({
    onSceneLoaded: function () {
        this._super();

        var openCardsView = this.openCardsControl = new OpenCardsView(this.game.open);
        this.addChild(openCardsView);

        var shuffleBooster = cleverapps.boosters.getBoosterById(cleverapps.Boosters.TYPE_TILE_SHUFFLE);
        if (shuffleBooster && shuffleBooster.isAvailable()) {
            var shuffleButton = new TileShuffleButtonBooster(shuffleBooster);
            this.addChild(shuffleButton);

            this.shuffleButton = new HidingNode(shuffleButton, cleverapps.UI.HORIZONTAL);
            cleverapps.meta.registerControl(shuffleButton.getControlId(), this.shuffleButton);
        }

        var undoBooster = cleverapps.boosters.getBoosterById(cleverapps.Boosters.TYPE_UNDO);
        if (undoBooster && undoBooster.isAvailable()) {
            var undoButton = new UndoButtonBooster(undoBooster);
            this.addChild(undoButton);

            this.undoButton = new HidingNode(undoButton, cleverapps.UI.HORIZONTAL);
            cleverapps.meta.registerControl(undoButton.getControlId(), this.undoButton);
        }

        var vacuumBooster = cleverapps.boosters.getBoosterById(cleverapps.Boosters.TYPE_VACUUM);
        if (vacuumBooster && vacuumBooster.isAvailable()) {
            var vacuumButton = new VacuumButtonBooster(vacuumBooster);
            this.addChild(vacuumButton);

            this.vacuumButton = new HidingNode(vacuumButton, cleverapps.UI.HORIZONTAL);
            cleverapps.meta.registerControl(vacuumButton.getControlId(), this.vacuumButton);
        }
    },

    outOfMoves: function () {
        var scene = this;
        cleverapps.meta.display({
            focus: "ProlongationWindow",
            actions: [
                function (f) {
                    cleverapps.meta.clearUpdateTimeout();
                    cleverapps.timeouts.setTimeout(f, OpenCards.OVERFLOW_ANIMATION_DURATION);
                },
                function (f) {
                    cleverapps.meta.updateControlsVisibility();
                    if (cleverapps.gameModes.skipProlongation) {
                        prolongationOptions.onGiveUp();
                        f();
                        return;
                    }

                    new ProlongationWindow({
                        onGiveUp: function () {
                            Game.currentGame.lose();
                        },
                        onSuccess: function () {
                            if (scene.game.open.extraSlot && scene.game.open.extraSlot.isOfferAvailable()) {
                                scene.game.open.extraSlot.trigger("removeExtraSlotButton", scene.game.open.extraSlot);
                            }
                        }
                    });

                    cleverapps.meta.onceNoWindowsListener = f;
                }
            ]
        });
    }
});

GameScene = Tile3Scene;
